import { VerifyEmailRequest } from '~/../libs/gportal-grpc/gportal/api/user/v1/requests_pb';
import { Code } from '@connectrpc/connect';

/**
 * Provides functionality vor email verification.
 * NOTE: Use this only in layouts and NOT in components, since the usage of this composable has to be a singleton.
 */
export default () => {
  const { $sentry } = useNuxtApp();
  const $grpcUser = useGrpcUser();
  const i18n = useI18n();
  const authStore = useAuthStore();
  const { pushSuccessNotification, pushErrorNotification } = useNotifications();

  const accessToken = useAccessToken();
  const route = useRoute();
  const router = useRouter();

  const cleanUrl = () => {
    router.replace({ path: route.path, query: null });
  };

  const confirmEmail = async () => {
    const { verification_code: verificationCode } = route.query as {
      verification_code: string;
    };

    if (verificationCode != null && verificationCode.length > 0) {
      // Not authenticated -> show error notification and return
      if (!authStore.user) {
        pushErrorNotification({
          title: i18n.t('general/error/text').toString(),
          message: i18n
            .t('register/verification/unauthenticated/message')
            .toString(),
        });

        return;
      }

      if (!authStore.user.emailVerified) {
        const verifyEmailRequest = new VerifyEmailRequest();
        verifyEmailRequest.verificationCode = verificationCode;

        const metaData = new Headers();
        metaData.set('authorization', `Bearer ${accessToken.value}`);

        const { error } = await $grpcUser.executeApiCallPrimary(
          'verifyEmail',
          verifyEmailRequest,
          metaData,
        );

        if (error != null) {
          if (error.code === Code.InvalidArgument) {
            pushErrorNotification({
              title: i18n.t('general/error/text').toString(),
              message: i18n
                .t('register/verification/code_invalid/message')
                .toString(),
            });
          } else {
            pushErrorNotification({
              title: i18n.t('general/error/text').toString(),
            });

            $sentry.captureException(error);
          }
          return;
        }

        try {
          await authStore.fetchUser();

          pushSuccessNotification({
            title: i18n.t('register/verification/success/title').toString(),
          });

          cleanUrl();
        } catch (fetchUserError) {
          pushErrorNotification({
            title: i18n.t('general/error/text').toString(),
          });

          $sentry.captureException(fetchUserError);
        }
      } else {
        cleanUrl();
      }
    }
  };

  onMounted(() => {
    confirmEmail();
  });
};

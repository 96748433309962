<template>
  <header class="w-100">
    <navigation-mobile-bar
      class="position-fixed top-0 w-100"
      @toggle-mobile-navigation="enableMobileNavigation"
    />
    <navigation-sidebar
      :is-opened="isMobileMenuToggled"
      @update:is-opened="(val) => (isMobileMenuToggled = val)"
    />
  </header>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const isMobileMenuToggled = ref<boolean>(false);
    const enableMobileNavigation = () => {
      isMobileMenuToggled.value = true;
    };

    return {
      isMobileMenuToggled,
      enableMobileNavigation,
    };
  },
});
</script>

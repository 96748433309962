export default () => {
  const route = useRoute();
  const isProfileRoute = computed(
    () => route.name != null && route.name.toString().startsWith('profile'),
  );

  const isLandingpageRoute = computed(
    () => route.name != null && route.name.toString().startsWith('landingpage'),
  );

  return { isProfileRoute, isLandingpageRoute };
};

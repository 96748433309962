<template>
  <aside
    id="navigation__sidebar-wrapper"
    ref="element"
    class="offcanvas offcanvas-start d-flex flex-column shadow"
  >
    <div
      class="flex-grow-1 d-flex flex-row py-8 ps-2 pe-6 ps-xl-8 overflow-auto"
    >
      <!-- close button column, only at xl size -->
      <div class="d-xl-none">
        <button
          class="sidebar__dismiss-btn btn px-3"
          data-bs-dismiss="offcanvas"
          type="button"
          aria-label="Close Sidebar"
        >
          <fa-icon class="text-rhino" :icon="faTimes" />
        </button>
      </div>
      <!-- menu column -->
      <div class="flex-grow-1 ps-2 px-xl-0">
        <router-link
          :to="homepageUrl"
          class="d-flex justify-content-center justify-content-md-start mb-4"
          :aria-label="t('general/menu/back-to-home')"
        >
          <common-gportal-logo data-cy="main-logo" class="sidebar__logo" />
        </router-link>
        <router-link
          :to="indexUrl"
          class="sidebar__button btn btn-primary btn-lg mb-8"
          :class="{
            'd-xl-none': isLandingpageRoute,
          }"
        >
          {{ $t('general/navigation/rent_server') }}
        </router-link>
        <!-- <ul v-else class="list-group">
          <li class="list-group-item">
            <a
              class="navigation__menu__link navigation__menu__label"
              :href="state.navigation.uplink.data.url"
            >
              <i class="icon far fa-arrow-alt-circle-left"></i>
              <strong>{{ translateLabel(state.navigation.uplink) }}</strong>
            </a>
          </li>
        </ul> -->
        <template v-if="authStore.isAuthenticated">
          <lazy-navigation-sidebar-secondlevel-profile v-if="isProfileRoute" />
          <lazy-navigation-sidebar-firstlevel-authenticated v-else />
        </template>
        <lazy-navigation-sidebar-firstlevel-anonymous v-else />
      </div>
    </div>
    <navigation-sidebar-quickmenu class="mx-0 py-4" />
  </aside>
</template>
<script lang="ts" setup>
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMobileBehaviour as useSidebarMobileBehaviour } from './features/useMobileBehaviour';
import useNavigationBasePages from './features/useNavigationBasePages';
import useSecondLevelNavigation from './features/useSecondLevelNavigation';

defineComponent({
  name: 'SidebarWrapper',
});

const props = defineProps<{
  isOpened: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isOpened', val: boolean): void;
}>();

const { t } = useI18n();

// third party
const authStore = useAuthStore();

// navigation data
const { indexUrl, homepageUrl } = useNavigationBasePages();
const { isProfileRoute, isLandingpageRoute } = useSecondLevelNavigation();

// DOM related
const element = ref<HTMLElement>();

// mobile behaviour
useSidebarMobileBehaviour(toRef(props, 'isOpened'), element, emit);
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';

.offcanvas {
  width: 100%;
  background-color: $gp-midnight-1;
  overflow-y: auto;
  visibility: visible;

  @include media-breakpoint-up(md) {
    width: 320px;
  }

  @include media-breakpoint-up(xl) {
    transform: none;
    // we have to override the visibility because it is applied to the component itself by Bootstrap JS
    visibility: visible !important;
  }
}

$header-elements-width: 200px;

.sidebar {
  &__dismiss {
    &-btn {
      margin-top: -0.5rem;
      font-size: 1.3rem;
    }
  }

  @include media-breakpoint-up(xl) {
    &__logo {
      width: $header-elements-width;
      height: 2rem;
    }
  }

  &__button {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    &__button {
      width: auto;
      min-width: $header-elements-width;
    }
  }
}
</style>

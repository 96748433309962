<template>
  <lazy-navigation-sidebar-quickmenu-authenticated
    v-if="store.isAuthenticated"
    class="sidebar__quickmenu"
  />
  <div v-else class="sidebar__quickmenu row g-0">
    <div class="col col-3 text-center">
      <button
        class="quickmenu__link d-block mx-auto p-1 rounded-circle"
        :aria-label="t('general/non_special_text/login')"
        type="button"
        @click="onLoginClick"
      >
        <fa-icon class="quickmenu__icon text-rhino" :icon="faUser" />
      </button>
      <p class="mb-0 text-capitalize">
        {{ t('general/non_special_text/login') }}
      </p>
    </div>

    <div class="col col-3 text-center">
      <nuxt-link
        :to="`/${locale}/register`"
        class="quickmenu__link d-block mx-auto p-1 rounded-circle"
        :aria-label="t('frontend/register')"
        :prefetch="false"
      >
        <fa-icon class="quickmenu__icon text-rhino" :icon="faPenToSquare" />
      </nuxt-link>

      <p class="mb-0 text-capitalize">
        {{ t('frontend/register') }}
      </p>
    </div>

    <!-- <div class="col col-3 text-center">
      <button
        class="quickmenu__link d-block mx-auto p-1 rounded-circle"
        :class="{ 'flex--grow-3': !state.hasUser }"
        @click="emit('openSearch')"
      >
        <fa-icon class="quickmenu__icon text-rhino" :icon="['fas', 'search']" />
      </button>
      <p class="mb-0 text-capitalize">
        {{ i18n.t('general-non_special_text-search') }}
      </p>
    </div> -->
  </div>
</template>
<script lang="ts">
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import useAuth from '~/composables/useAuth';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  name: 'SidebarQuickMenu',
  emits: ['openSearch'],
  setup(_props, { emit }) {
    const store = useAuthStore();
    const { t, locale } = useI18n();

    const { login } = useAuth();
    const onLoginClick = () => {
      login();
    };

    const { region } = useRegion();

    return {
      t,
      locale,

      region,
      store,
      emit,
      onLoginClick,

      faUser,
      faPenToSquare,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';

:deep(.quickmenu__link) {
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color 0.3s ease;
  border: none;
  background: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

:deep(.quickmenu__icon) {
  width: 1.5rem;
  height: 1.5rem;
}
</style>

import useBootstrapOffcanvas from '~/composables/bootstrap/useOffcanvas';

export const useMobileBehaviour = (
  isOpened: Ref<boolean>,
  element: Ref<HTMLElement>,
  emit: (e: 'update:isOpened', value: boolean) => void,
) => {
  const route = useRoute();
  const isOpenedRef = ref<boolean>(isOpened.value);
  const breakpoints = useAppBreakpoints();

  const handleSidebarShow = () => {
    isOpenedRef.value = true;
    emit('update:isOpened', isOpenedRef.value);
  };

  const handleSidebarClose = () => {
    isOpenedRef.value = false;
    emit('update:isOpened', isOpenedRef.value);
  };

  const { instance } = useBootstrapOffcanvas(
    element,
    null,
    handleSidebarShow,
    handleSidebarClose,
  );

  const openSidebar = () => {
    if (instance.value == null) return;
    // automatically invokes bs shown event
    instance.value.show();
    isOpenedRef.value = true;
  };

  const closeSidebar = () => {
    if (instance.value == null) return;
    // automatically invokes bs closed event
    instance.value.hide();
    isOpenedRef.value = false;
  };

  /**
   * handled by manual close and open when mobile bar is visible
   */
  watch(
    () => isOpened.value,
    (newValue) => {
      if (newValue) {
        openSidebar();
      } else {
        closeSidebar();
      }
    },
  );

  /**
   * close sidebar when navigating
   */
  watch(() => route.path, closeSidebar);

  /**
   * force closing sidebar when reszing above or below target breakpoint
   */
  throttledWatch(
    breakpoints.greater('xl'),
    () => {
      closeSidebar();
    },
    { throttle: 200 },
  );

  return {
    handleSidebarShow,
    handleSidebarClose,
  };
};

export default {};

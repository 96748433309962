<template>
  <nav
    class="mobilebar py-4 px-4 d-flex align-items-center justify-content-start d-xl-none"
  >
    <button
      class="btn px-3"
      aria-label="Open Menu"
      type="button"
      @click.stop="toggleMobileNavigation"
    >
      <fa-icon class="text-rhino" :icon="faBars" />
    </button>

    <div class="flex-grow-1 d-flex">
      <router-link
        class="mx-auto mx-md-0"
        :to="homepageUrl"
        :aria-label="t('general/menu/back-to-home')"
      >
        <common-gportal-logo />
      </router-link>
    </div>

    <button
      v-if="authStore.isAuthenticated"
      class="btn btn-transparent px-3"
      :aria-label="t('profile/modal/switch_accounts')"
      type="button"
      @click="openUserSwitchModal"
    >
      <div class="position-relative">
        <common-avatar class="mobilebar__avatar" />
        <span
          class="mobilebar__region-badge badge position-absolute end-0 bottom-0 fs-tiny"
        >
          {{ getRegionLabel(authStore.activeRegion) }}
        </span>
      </div>
    </button>

    <button
      v-else
      class="btn btn-transparent px-3"
      :aria-label="t('frontend/my-account')"
      type="button"
      @click="() => loginToAccount()"
    >
      <fa-icon class="text-rhino" :icon="faUser" />
    </button>
  </nav>
</template>

<script lang="ts">
import { faBars, faUser } from '@fortawesome/pro-regular-svg-icons';
import useNavigationBasePages from './sidebar/features/useNavigationBasePages';
import { OPEN_MODAL_SYMBOL } from './symbols';
import useAuth from '~/composables/useAuth';

export default defineComponent({
  emits: ['toggleMobileNavigation'],
  setup(_props, { emit }) {
    const { t, locale } = useI18n();
    const authStore = useAuthStore();
    const { homepageUrl } = useNavigationBasePages();
    const { region } = useRegion();

    const toggleMobileNavigation = () => emit('toggleMobileNavigation');

    const isModalOpen = inject(OPEN_MODAL_SYMBOL);
    const openUserSwitchModal = () => {
      if (isModalOpen == null) return;
      isModalOpen.value = true;
    };

    const { login } = useAuth();

    const loginToAccount = () => {
      login(`/${locale.value}/profile`);
    };

    return {
      t,
      authStore,
      homepageUrl,
      region,
      getRegionLabel,
      loginToAccount,
      toggleMobileNavigation,
      openUserSwitchModal,

      login,

      faBars,
      faUser,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'gportal-theme/scss/colors.scss';
@import '~/assets/styles/gportal/variables';
.mobilebar {
  z-index: 100;
  min-height: $mobile-bar-height;
  background: linear-gradient(
    -90deg,
    $gp-midnight-3-b 0%,
    $gp-midnight-3-a 100%
  );
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.3);

  &__avatar {
    width: 2rem;
    height: 2rem;
  }

  &__region-badge {
    top: unset;
    font-size: 0.7rem;
    padding: 0.1rem 0.2rem;
    margin-right: -0.5rem;
    background-color: $gp-rhino;
  }
}
</style>

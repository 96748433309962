<template>
  <div>
    <client-only>
      <lazy-reflink-banner />
      <lazy-common-notifications class="notifications" />
    </client-only>
    <navigation-the-page-navigation />
    <div class="gp-app">
      <slot></slot>
    </div>
    <div class="gp-footer">
      <footer-the-page-footer class="container" />
    </div>
    <lazy-admin-bar v-if="customerIsAdmin" />
    <lazy-navigation-sidebar-user-modal
      v-if="isAuthenticated && hasActiveCustomer"
      :is-modal-open="isUserModalOpen"
      @modal-hidden="isUserModalOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { OPEN_MODAL_SYMBOL } from '~/components/navigation/symbols';

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const customerIsAdmin = computed(() => {
      const activeCustomer = authStore.activeCustomer;
      if (activeCustomer == null) return false;
      return activeCustomer.isAdmin;
    });

    const isAuthenticated = computed(() => authStore.isAuthenticated);

    const hasActiveCustomer = computed(() => authStore.activeCustomer != null);

    //
    useEmailConfirm();
    useNewsletterNotification();

    //
    const isUserModalOpen = ref<boolean>(false);
    provide(OPEN_MODAL_SYMBOL, isUserModalOpen);

    return {
      customerIsAdmin,
      isAuthenticated,
      authStore,
      hasActiveCustomer,
      isUserModalOpen,
    };
  },
});
</script>

<style lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import '~/assets/styles/gportal/variables';

$breakpoints: (
  md: 768px,
  xl: 1200px,
);

@mixin pullright {
  @include media-breakpoint-up(xl, $breakpoints) {
    margin-left: $desktop-left-offset;
    width: calc(100% - $desktop-left-offset);
  }
}

.gp-footer {
  @include pullright();
}

.gp-app {
  @include pullright();
  margin-top: 4.5rem;

  @include media-breakpoint-up(xl, $breakpoints) {
    margin-top: 0;
  }
}
</style>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import '~/assets/styles/gportal/variables';

$breakpoints: (
  md: 768px,
  xl: 1200px,
);

.notifications {
  z-index: 1056;
  margin-top: 4.5rem;
  width: 100%;
  max-width: 37rem;

  @include media-breakpoint-up(xl, $breakpoints) {
    margin-top: 0;
  }
}

:deep(.admin-bar) {
  z-index: 10;
}
</style>

/**
 * Provides functionality for showing a notification when a user has confirmed their newsletter subscription.
 * NOTE: Use this only in layouts and NOT in components, since the usage of this composable has to be a singleton.
 */
export default () => {
  const i18n = useI18n();
  const { pushSuccessNotification } = useNotifications();
  const route = useRoute();
  const router = useRouter();

  const cleanUrl = () => {
    router.replace({ path: route.path, query: undefined });
  };

  const newsletterNotification = () => {
    const { newsletter_opt_in: newsletterOptIn } = route.query as {
      newsletter_opt_in: string;
    };

    if (newsletterOptIn != null && newsletterOptIn == '1') {
      pushSuccessNotification({
        title: i18n.t('register/newsletter/opt_in/success').toString(),
      });

      cleanUrl();
    }
  };

  onMounted(() => {
    newsletterNotification();
  });
};
